import React, { useState } from 'react';
import './contact.css'
import back from '../../assets/image 69.png'
import dalogo from '../../assets/dalogo.png'
import axios from 'axios';
import { showSuccessToast, showErrorToast } from '../../toastutils';

function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    request_type: "contact",
    company_name: '',
    email_id: '',
    mobile_number: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.deepalgorithms.in/contact', formData);
      if (response.status == 200) {
        showSuccessToast('Details submitted successfully')
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      showErrorToast('Error submitting details')
    }
    setFormData({
      name: '',
      request_type: '',
      company_name: '',
      email_id: '',
      mobile_number: '',
      message: ''
    });
  };

  return (
    <>

      <div className="contact">
        <div className="left"></div>
        <div className="contact-grad"></div>
        <img src={back} alt="solution" className="responsive-image" />

        <div className="contact-form">
          <h1>Contact us</h1>
          <div className="main">
            <div className="left1">

              <img src={dalogo} alt="dalogo" style={{ width: '25%' }} />
              <div className="text">
                <h4>Our locations</h4>
                <h3>India</h3>
                <p>CIE, Vindhya C4<br />IIIT Hyderabad, Gachibowli<br /> Hyderabad, Telangana, 500032<br />Mobile:  +91 9963552791 <br /> email: info@deepalgorithms.in</p>
              </div>
              <div className="text1">
                <h3>USA</h3>
                <p>14514 Ascot Square Court <br />Boyds, MD 20841 <br />Mobile: +1 (240) 646 2259 <br />Email: info@deepalgorithms.in</p>
              </div>
            </div>

            <div className="right">
              <p>Drop us a message</p>
              <form id='form' className='form1' onSubmit={handleSubmit}>
                <label>Your name <span className="star">*</span></label><br />
                <input type="text" name='name' required value={formData.name} onChange={handleChange} /><br />
                <label>Company <span className="star">*</span></label><br />
                <input type="text" name='company_name' required value={formData.company_name} onChange={handleChange} /><br />
                <label>Email <span className="star">*</span></label><br />
                <input type="text" name='email_id' required value={formData.email_id} onChange={handleChange} /><br />
                <label>Mobile <span className="star">*</span></label><br />
                <input type="text" name='mobile_number' required value={formData.mobile_number} onChange={handleChange} /><br />
                <label>Message<span className="star">*</span></label><br />
                <textarea name="message" required value={formData.message} onChange={handleChange}></textarea>
                <div></div>
                <button className='btn'>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Contact;
