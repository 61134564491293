import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ScrollToTop from './components/scrolltop';
import { useState } from 'react';
import DA from "../src/assets/logo.png";
import DAend from '../src/assets/DA-logo.png';
import linked from '../src/assets/Vector.png';
import twitter from '../src/assets/twitter.png';
import drop from '../src/assets/expand_more.png';
import fb from '../src/assets/Vector1.png';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Home from './components/home/Home';
import Adaptive from './components/products/adaptive/adaptive';
import Passwordless from './components/products/passwordless/passwordless';
import Continuous from './components/products/continuous/continuous';
import Ztna from './components/products/ztna/ztna';
import Penetration from './components/services/penetration/penetration';
import GRC from './components/services/grc/grc';
import DataAnalytics from './components/services/dataAnalytics/dataAnalytics';
import Chatbot from './components/services/chatbots/chatbot';
import Cctv from './components/services/cctv/cctv';
import Cloud from './components/services/cloud/cloud';
import Biometrics from './components/solutions/biometrics/biometrics';
import Password from './components/solutions/passwordless/passwordless';
import Productivity from './components/solutions/productivity/productivity';
import SocialEng from './components/solutions/socialeng/socialeng';
import Dataleak from './components/solutions/sso/sso';
import Demo from './components/demo/demo';
import { ToastContainer } from 'react-toastify';


function App() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <div>
        <ScrollToTop>
        <ToastContainer/>
          <div className="main-container">
            <nav className="navbar">
              <div className="logo"><Link to="/"><img src={DA} alt="logo" width="150px"/></Link></div>
              <ul className={`nav-list ${isMenuOpen ? "" : "hidden"}`}>
                <li><Link to="#">Products
                  <img style={{ marginLeft: '5px' }} src={drop} alt='dropdown' /></Link>
                  <ul className='dropdown'>
                    <p style={{ fontSize: '16px', fontWeight: '600', padding: '2%' }}>Products</p>
                    <div className="border"></div>
                    <li><Link to="/adaptive" onClick={closeMenu}>
                      <p className="pdropdown">AI Adaptive Behavioral Authentication</p>
                    </Link></li>
                    <li><Link to="/passwordless" onClick={closeMenu}>
                      <p className="pdropdown">AI Password-less Authentication</p>
                    </Link></li>
                    <li><Link to="/continuous" onClick={closeMenu}>
                      <p className="pdropdown" >AI Continuous Authentication & Monitoring</p>
                    </Link></li>
                    <li><Link to="/ztna" onClick={closeMenu}>
                      <p className="pdropdown" >AI Cloud ZTNA</p>
                    </Link></li>
                  </ul>
                </li>
                <li><Link to="#">Solutions
                  <img style={{ marginLeft: '5px' }} src={drop} alt='dropdown' /></Link>
                  <ul className='dropdown'>
                    <p style={{ fontSize: '16px', fontWeight: '600', padding: '2%' }}>Solutions</p>
                    <div className="border"></div>
                    <li><Link to="/biometrics" onClick={closeMenu}>
                      <p className="pdropdown">Behavioral Biometrics ZTA</p>
                    </Link></li>
                    <li><Link to="/password" onClick={closeMenu}>
                      <p className="pdropdown">Passwordless Authentication</p>
                    </Link></li>
                    <li><Link to="/productivity" onClick={closeMenu}>
                      <p className="pdropdown">Productivity Monitoring Solution</p>
                    </Link></li>
                    <li><Link to="/sso" onClick={closeMenu}>
                      <p className="pdropdown">SSO and Data leak Prevention ZTNA</p>
                    </Link></li>
                    <li><Link to="/socialengineering" onClick={closeMenu}>
                      <p className="pdropdown">Social Engineering Attacks Prevention</p>
                    </Link></li>

                  </ul>
                </li>
                <li><Link to="#">Services
                  <img style={{ marginLeft: '5px' }} src={drop} alt='dropdown' /></Link>
                  <ul className='dropdown'>
                    <p style={{ fontSize: '16px', fontWeight: '600', padding: '2%' }}>Services</p>
                    <div className="border"></div>

                    <li><Link to="/penetration" onClick={closeMenu}>
                      <p className="pdropdown">Penetration Testing</p>
                    </Link></li>
                    <li><Link to="/grc" onClick={closeMenu}>
                      <p className="pdropdown">GRC Services</p>
                    </Link></li>
                    <li><Link to="/chatbot" onClick={closeMenu}>
                      <p className="pdropdown">Artificial Intelligence</p>
                    </Link></li>
                    <li><Link to="/dataanalytics" onClick={closeMenu}>
                      <p className="pdropdown">Data Analytics</p>
                    </Link></li>

                    <li><Link to="/cloud" onClick={closeMenu}>
                      <p className="pdropdown">Cloud Engineering</p>
                    </Link></li>
                  </ul>
                </li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                {/* <li><Link to="/contact">Support</Link></li>
              <li><Link to="/contact">Docs</Link></li> */}
                <li className="request-demo"><Link to="/demo">Request Demo</Link></li>
              </ul>
              <div className="rightNav">
                <Link to="/demo" className="btnr btn-rd">Request Demo</Link>
              </div>
              <div className="nav-icon">
                <i className={`bi bi-list ${isMenuOpen ? "hide" : ""}`} onClick={toggleMenu}></i>
                <i className={`bi bi-x-lg ${isMenuOpen ? "" : "hide"}`} onClick={toggleMenu}></i>
              </div>
            </nav>
          </div>

          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            <Route exact path="/adaptive" element={<Adaptive />}></Route>
            <Route exact path="/passwordless" element={<Passwordless />}></Route>
            <Route exact path="/continuous" element={<Continuous />}></Route>
            <Route exact path="/ztna" element={<Ztna />}></Route>
            <Route exact path="/penetration" element={<Penetration />}></Route>
            <Route exact path="/grc" element={<GRC />}></Route>
            <Route exact path="/dataanalytics" element={<DataAnalytics />}></Route>
            <Route exact path="/chatbot" element={<Chatbot />}></Route>
            <Route exact path="/cctv" element={<Cctv />}></Route>
            <Route exact path="/cloud" element={<Cloud />}></Route>
            <Route exact path="/biometrics" element={<Biometrics />}></Route>
            <Route exact path="/password" element={<Password />}></Route>
            <Route exact path="/socialengineering" element={<SocialEng />}></Route>
            <Route exact path="/productivity" element={<Productivity />}></Route>
            <Route exact path="/sso" element={<Dataleak />}></Route>
            <Route exact path="/demo" element={<Demo />}></Route>
          </Routes>

          <div className="footer">
            <div>
              <img src={DAend} alt="logo" />
            </div>
            <div className="footer-row2">
              <h5 style={{ color: '#6C757D' }}>PRODUCTS</h5>
              <ul>
                <li><Link to="/adaptive">Adaptive Behavioral Authentication</Link></li>
                <li><Link to="/continuous">Continuous Monitoring</Link></li>
                <li><Link to="/passwordless">Password-less Authentication</Link></li>
                <li><Link to="/ztna">AI Cloud ZTNA</Link></li>
              </ul>
            </div>
            <div className="footer-row3">
              <h5 style={{ color: '#6C757D' }}>COMPANY</h5>
              <ul>
                <li><Link to="/about">About Us</Link></li>

                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
            <div className="footer-row4">
              <h5 style={{ color: '#6C757D' }}>SERVICES</h5>
              <li><Link to="/penetration">Penetration testing</Link></li>
              <li><Link to="/chatbot">Artificial Intelligence</Link></li>
              <li><Link to="/cloud">Cloud Engineering</Link></li>
              <li><Link to="/grc">GRC Services</Link></li>
              <li><Link to="/dataanalytics">Data Analytics</Link></li>
            </div>
            <div className="icons" >
              <ul>
                <li><a href='https://in.linkedin.com/company/deepalgorithms.in'>
                  <img src={linked} alt="Link Icon" /></a>
                
                </li>
                <li>
                  <img src={twitter} alt="Twitter Icon" />
               
                </li>
                <li>
                  <img src={fb} alt="Facebook Icon" />
                
                </li>
              </ul>
            </div>
          </div>
          <div className="end">
            <div className="text-6">
              <p style={{ color: '#ADB5BD' }}>© 2024 Deep Algorithms. All Right Reserved.</p>
            </div>
          </div>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
